<template>
  <div class="mainBox">
    <!-- src="https://gw.alipayobjects.com/v/huamei_gcee1x/afts/video/jXRBRK_VAwoAAAAAAAAAAAAAK4eUAQBr" -->
    <!-- src="https://www.monnetpayments.com/wp-content/uploads/2022/09/Monnet-video-portada.mp4" -->
    <video
      src="../../assets/login-video.mp4"
      muted
      preload
      autoplay
      loop
      class="main-video"
      ref="video"
    />

    <!-- <div class="left">Welcome Dashboard!</div> -->
    <div class="left">
      <h1 class="left-title"><div>SECURITY OPENNESS TECHNOLOGY</div> BUILD WEALTH TOGETHER</h1>
      <div class="left-line"></div>
      <div class="left-desc">
				<p>Establish a global, convenient and secure payment network to make business easier</p>						
      </div>
    </div>
    <div class="right">
      <div class="rightBox">
        <div class="title">BestPay</div>
        <a-form id="formLogin" class="user-layout-login" ref="formLogin" :form="form" @submit="handleSubmit">
          <a-form-item>
            <a-input
              size="large"
              type="text"
              :placeholder="$t('userName')"
              v-decorator="[
                'username',
                {
                  rules: [{ required: true, message: $t('home.userNameTips') }, { validator: handleUsernameOrEmail }],
                  validateTrigger: 'change',
                },
              ]"
            >
              <a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }" />
            </a-input>
          </a-form-item>

          <a-form-item>
            <a-input
              size="large"
              type="password"
              autocomplete="false"
              :placeholder="$t('password')"
              v-decorator="[
                'password',
                { rules: [{ required: true, message: $t('home.passwordTips') }], validateTrigger: 'blur' },
              ]"
            >
              <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }" />
            </a-input>
          </a-form-item>
          <div style="position: relative">
            <a @click="SwitchLang">{{ $i18n.locale == lang.zhCN ? '使用中文' : 'Use English' }}</a>
          </div>
          <a-form-item style="margin-top: 24px">
            <a-button
              size="large"
              type="primary"
              htmlType="submit"
              class="login-button"
              :loading="state.loginBtn"
              :disabled="state.loginBtn"
              >{{ $t('confirm') }}</a-button
            >
          </a-form-item>
        </a-form>
      </div>
    </div>

    <two-step-captcha
      v-if="requiredTwoStepCaptcha"
      :visible="stepCaptchaVisible"
      @success="stepCaptchaSuccess"
      @cancel="stepCaptchaCancel"
    ></two-step-captcha>
  </div>
</template>

<script>
import TwoStepCaptcha from '@/components/tools/TwoStepCaptcha'
import { mapActions } from 'vuex'
import { timeFix } from '@/utils/util'
import { getSmsCaptcha } from '@/api/login'
import lang from '../../i18n/lang'
import i18n from '../../i18n'

export default {
  components: {
    TwoStepCaptcha,
  },
  data() {
    return {
      lang,
      customActiveKey: 'tab1',
      loginBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      isLoginError: false,
      requiredTwoStepCaptcha: false,
      stepCaptchaVisible: false,
      form: this.$form.createForm(this),
      state: {
        time: 60,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false,
      },
      redirect: undefined,
    }
  },
  computed: {},
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true,
    },
  },
  created() {
    // this.requiredTwoStepCaptcha = true
  },
  mounted() {
    setTimeout(() => {
      const video = this.$refs.video

      video.onloadstart = () => {
        console.log('load')
        video.muted = true
        video.play()
      }

      video.onended = () => {
        video.play()
      }
    }, 100)
  },
  methods: {
    ...mapActions(['Login', 'Logout']),
    ...mapActions('language', ['setLanguage']),
    SwitchLang() {
      const key = this.$i18n.locale == lang.zhCN ? lang.enUS : lang.zhCN
      this.$i18n.locale = key
      this.setLanguage(key)
    },
    // handler
    handleUsernameOrEmail(rule, value, callback) {
      const { state } = this
      const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
      if (regex.test(value)) {
        state.loginType = 0
      } else {
        state.loginType = 1
      }
      callback()
    },
    handleTabClick(key) {
      this.customActiveKey = key
      // this.form.resetFields()
    },
    handleSubmit(e) {
      e.preventDefault()
      const {
        form: { validateFields },
        state,
        customActiveKey,
        Login,
      } = this

      state.loginBtn = true

      const validateFieldsKey = customActiveKey === 'tab1' ? ['username', 'password'] : ['mobile', 'captcha']

      validateFields(validateFieldsKey, { force: true }, (err, values) => {
        if (!err) {
          const loginParams = {
            loginName: values.username,
            password: values.password,
          }
          Login(loginParams)
            .then((res) => this.loginSuccess(res))
            .catch((err) => {
              this.requestFailed(err)
            })
            .finally(() => {
              state.loginBtn = false
            })
        } else {
          setTimeout(() => {
            state.loginBtn = false
          }, 600)
        }
      })
    },
    getCaptcha(e) {
      e.preventDefault()
      const {
        form: { validateFields },
        state,
      } = this

      validateFields(['mobile'], { force: true }, (err, values) => {
        if (!err) {
          state.smsSendBtn = true

          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              state.time = 60
              state.smsSendBtn = false
              window.clearInterval(interval)
            }
          }, 1000)

          const hide = this.$message.loading('验证码发送中..', 0)
          getSmsCaptcha({ mobile: values.mobile })
            .then((res) => {
              setTimeout(hide, 2500)
              this.$notification['success']({
                message: '提示',
                description: '验证码获取成功，您的验证码为：' + res.result.captcha,
                duration: 8,
              })
            })
            .catch((err) => {
              setTimeout(hide, 1)
              clearInterval(interval)
              state.time = 60
              state.smsSendBtn = false
              this.requestFailed(err)
            })
        }
      })
    },
    stepCaptchaSuccess() {
      this.loginSuccess()
    },
    stepCaptchaCancel() {
      this.Logout().then(() => {
        this.loginBtn = false
        this.stepCaptchaVisible = false
      })
    },
    loginSuccess(res) {
      window.location.reload()
      const self = this
      // 延迟 1 秒显示欢迎信息
      setTimeout(() => {
        const message = i18n.tc('home.welcome')
        const description = `${i18n.tc(timeFix())}, ${i18n.tc('home.welcomeBack')}`
        this.$notification.success({
          message,
          description,
        })
      }, 1000)
      this.isLoginError = false
    },
    requestFailed(err) {
      const self = this
      this.isLoginError = true
      this.$notification['error']({
        message: self.$t('error'),
        description: err.message || self.$t('requestFail'),
        duration: 4,
      })
    },
  },
}
</script>

<style lang="less" scoped>
* {
  margin: 0px;
  padding: 0px;
}
// .ant-btn-primary {
//   background: #cdaa7d !important;
//   border-color: #cdaa7d !important;
// }
// a {
//   color: #cdaa7d !important;
// }
// .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
//   border-color: #cdaa7d !important;
//   border-right-width: 1px !important;
// }
.mainBox {
  width: 100vw !important;
  height: 100vh !important;
  overflow: hidden !important;
  // background-image: url('../../assets/loginImg/Background_Login.png');
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0px !important;
  display: flex;
  position: relative;
  // background: rgba(0, 0, 0, 0.9);
  background-color: #020078;
  .main-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
  .right {
    position: relative;
    margin: auto 120px auto auto;
    width: 460px;
    max-width: 100%;
    // background: #fff;
    height: 100%;
    padding: 0px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // align-items: flex-end ;
    justify-content: center;

    .rightBox {
      width: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(4px);
      background-image: radial-gradient(
        circle at 93% 12%,
        rgba(22, 119, 255, 0.17) 0%,
        rgba(255, 255, 255, 0.05) 23%,
        rgba(255, 255, 255, 0.03) 87%,
        rgba(22, 119, 255, 0.12) 109%
      );
      padding: 32px;
      box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);

      .title {
        // color: #cdaa7d;
        color: #fff;
        width: 100%;
        text-align: center;
        font-size: 36px;
        font-weight: 600;
        margin-bottom: 60px;
      }
    }
  }
  .left {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    text-align: left;
    font-weight: 700;
    line-height: 1;
    color: #fff;

    h1 {
      color: #45CCDD;
      font-family: "Poppins", Sans-serif;
      font-size: 46px;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1.15em;
      // width: 500px;
      margin-left: 150px;
      // margin: 0 auto;
    }
    .left-line {
      height: 10px;
      width: 100px;
      background: #45CCDD;
      margin-left: 150px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .left-desc {
      width: 500px;
      // margin: 0 auto;
      color: #FFFFFF;
      font-family: "Poppins", Sans-serif;
      font-size: 1.3rem;
      font-weight: 400;
      margin-left: 150px;
    }
  }
}
@media screen and (max-width: 400px) {
  .left {
    display: none;
  }
}
.ant-form-item {
  margin-bottom: 40px;
}
.user-layout-login {
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .forge-password {
    font-size: 14px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .item-icon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #cdaa7d;
      }
    }

    .register {
      float: right;
    }
  }
}
</style>
