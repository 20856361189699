var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mainBox"},[_c('video',{ref:"video",staticClass:"main-video",attrs:{"src":require("../../assets/login-video.mp4"),"muted":"","preload":"","autoplay":"","loop":""},domProps:{"muted":true}}),_vm._m(0),_c('div',{staticClass:"right"},[_c('div',{staticClass:"rightBox"},[_c('div',{staticClass:"title"},[_vm._v("BestPay")]),_c('a-form',{ref:"formLogin",staticClass:"user-layout-login",attrs:{"id":"formLogin","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'username',
                {
                  rules: [{ required: true, message: _vm.$t('home.userNameTips') }, { validator: _vm.handleUsernameOrEmail }],
                  validateTrigger: 'change',
                },
              ]),expression:"[\n                'username',\n                {\n                  rules: [{ required: true, message: $t('home.userNameTips') }, { validator: handleUsernameOrEmail }],\n                  validateTrigger: 'change',\n                },\n              ]"}],attrs:{"size":"large","type":"text","placeholder":_vm.$t('userName')}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'password',
                { rules: [{ required: true, message: _vm.$t('home.passwordTips') }], validateTrigger: 'blur' },
              ]),expression:"[\n                'password',\n                { rules: [{ required: true, message: $t('home.passwordTips') }], validateTrigger: 'blur' },\n              ]"}],attrs:{"size":"large","type":"password","autocomplete":"false","placeholder":_vm.$t('password')}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('div',{staticStyle:{"position":"relative"}},[_c('a',{on:{"click":_vm.SwitchLang}},[_vm._v(_vm._s(_vm.$i18n.locale == _vm.lang.zhCN ? '使用中文' : 'Use English'))])]),_c('a-form-item',{staticStyle:{"margin-top":"24px"}},[_c('a-button',{staticClass:"login-button",attrs:{"size":"large","type":"primary","htmlType":"submit","loading":_vm.state.loginBtn,"disabled":_vm.state.loginBtn}},[_vm._v(_vm._s(_vm.$t('confirm')))])],1)],1)],1)]),(_vm.requiredTwoStepCaptcha)?_c('two-step-captcha',{attrs:{"visible":_vm.stepCaptchaVisible},on:{"success":_vm.stepCaptchaSuccess,"cancel":_vm.stepCaptchaCancel}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('h1',{staticClass:"left-title"},[_c('div',[_vm._v("SECURITY OPENNESS TECHNOLOGY")]),_vm._v(" BUILD WEALTH TOGETHER")]),_c('div',{staticClass:"left-line"}),_c('div',{staticClass:"left-desc"},[_c('p',[_vm._v("Establish a global, convenient and secure payment network to make business easier")])])])
}]

export { render, staticRenderFns }